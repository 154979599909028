<template>
  <div>
        <div class="collapse" :style="skin.header_mobil.nav_bar" id="navbarToggleExternalContent" style="max-height: 90vh;overflow: auto;">
            <div class="p-1">
                <div class="my-1" style="line-height:10px">
                    <qbet v-bind:skin="skin"
                    v-bind:language="language"
                    v-bind:translation="translation"
                    />
                </div>
            </div>
        </div>
        <!-- Button trigger modal -->
        <button id="login_btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#login" style="display:none;">
        </button>
        <button id="register_btn" class="btn btn-primary" data-bs-toggle="modal" href="#registerModalToggle" style="display:none;"></button>
        <button id="chanpass_btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#changePassword" style="display:none;"></button>
        <login
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
        />
        <register
            ref="register"
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
        />
        <change-password
            v-bind:skin="skin"
            v-bind:language="language"
            v-bind:translation="translation"
            v-bind:user="user"
        />
        <nav v-if="user.user_data.data && skin.header_desktop.enabled" class="navbar navbar-dark py-0" id="mainHeader" style="position: fixed;width: 100%;">
            <my-account
                v-bind:skin="skin"
                v-bind:language="language"
                v-bind:translation="translation"
            />
            <div class="container-fluid p-2 py-2" :style="skin.header_mobil.nav_bar" style="width:100%;height: 65px;">
                <button class="navbar-toggler position-relative" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <span :style="skin.header_mobil.nav_bar_text"><i class="fas fa-bars"></i></span>
                </button>
                <div class="p-1">
                    <span class="px-2 pe-2 p-1" :style="skin.header_desktop.servicios_text2" data-bs-toggle="modal" data-bs-target="#myAccount"> <span style="font-size:large;"><i class="fas fa-user"></i></span> </span>
                    <!-- <span @click="my_account" style="float:right" :style="skin.header_mobil.nav_bar_text"><i class="fas fa-user"></i></span> -->
                </div>
                <div v-if="user.user_data.data.rol!='RESELLER' && skin.my_account.user_recharge.enabled">
                    <span @click="show_user_recharge" class="px-2 pe-2 p-1" :style="skin.header_desktop.servicios_text2" data-bs-toggle="modal" data-bs-target="#myAccount"> <span style="font-size:large;"><i class="fas fa-dollar-sign"></i></span> </span>
                </div>
                <div @click="changeBalanceView" style="font-size:small;display:table;" :style="skin.header_mobil.nav_bar_text" class="p-0 text-center">
                    <div style="display:table-cell;vertical-align:middle;">
                        <span>{{translate(balance.view=="SALDO"?"balance":"available")}}</span><br>
                        <span v-if="skin.conversion ? (skin.conversion.enabled ? true : false) : false" :style="skin.conversion.text">{{ user.user_data.data ? new Intl.NumberFormat("de-DE",{minimumFractionDigits: skin.qBet_config.decimalsQty,maximumFractionDigits: skin.qBet_config.decimalsQty}).format(balanceParsed(balance.saldo,currency.fiat))+' '+currency.fiat : 0 }}<br></span>
                        <span>{{ user.user_data.data ? new Intl.NumberFormat("de-DE",{minimumFractionDigits: skin.qBet_config.decimalsQty,maximumFractionDigits: skin.qBet_config.decimalsQty}).format(balance.saldo)+' '+user.user_data.data.currency : 0 }}</span>
                    </div>
                </div>
                <div v-if="skin.logout" @click="chanpass" class="px-2 pe-2 p-1 far fa-list-alt me-1" :style="skin.header_desktop.servicios_text2"> </div>

                <div v-if="skin.logout" class="p-2" :style="skin.header_desktop.servicios_text2">
                    <span @click="logout" style="font-size:large;" ><i class="fas fa-sign-out"></i></span>
                </div>
            </div>
        </nav>
        <nav  v-else-if="skin.header_desktop.enabled" class="navbar navbar-dark py-0" id="mainHeader" style="position: fixed;width: 100%;">
            <div class="d-flex p-3 py-3" :style="skin.header_mobil.nav_bar" style="height: 65px;">
                <button class="navbar-toggler position-relative" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <span style="color:white"><i class="fas fa-bars"></i></span>
                </button>
                <div><img class="mx-2" :src="skin.header_mobil.logo" :width="skin.header_mobil.logo_width" alt=""></div>
                <div v-if="skin.register" class="p-1 px-2 pe-2 me-1 text-center" @click="register" :style="skin.header_mobil.register_btn">{{translate("sign_in")}}</div>
                <div v-if="skin.login" class="p-1 px-2 pe-2 text-center" @click="login" :style="skin.header_mobil.login_btn">{{translate("enter")}}</div>
            </div>
        </nav>
        <nav v-else class="navbar navbar-dark py-0" id="mainHeader" style="position: fixed;width: 100%;">
            <div v-if="!skin.header_desktop.enabled" class="d-flex p-3 py-3" style="height: 65px;">
                <button class="navbar-toggler position-relative" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <span style="color:white"><i class="fas fa-magnifying-glass"></i></span>
                </button>
            </div>
        </nav>
    </div>
</template>

<script>
import myAccount from "./my_account.vue";
import login from "./login.vue";
import changePassword from './change_password.vue';
import qbet from '../main_bet/Qbet.vue';
import register from './register.vue';
export default {
    name:"header_mobil",
    props:["skin","translation","language"],
    components:{qbet,myAccount,login,register,changePassword},
    data(){
        return {
            balance:{
                saldo:0,
                conversion:0,
                view:"SALDO",
                switch:1
            }
        }
    },
    computed:{
        user(){
            let config = this.$store.getters['getConfig'];
            let data = this.$store.getters['getUserData'];
            if(data.user_data.data){
                this.balance.saldo = config.SkinConfig.main.newbonus ? data.user_data.data.available_balance + data.user_data.data.bonus + data.user_data.data.frozen: data.user_data.data.balance;
                this.balance.conversion = data.user_data.data.balance * data.user_data.data.tasa;
            }
            return data;
         },
        currency(){
            return this.$store.getters['getCurrency'];
         },
         config(){
            return this.$store.getters['getConfig'];
        },
    },
    methods:{
        show_user_recharge(){
            this.$root.$emit("show_user_recharge");
        },
        balanceParsed(val,fiat){
            let tasa = 1;
            if(this.$props.skin.conversion ? (this.$props.skin.conversion.enabled ? true : false) : false){
                tasa = this.currency.rate;
            }
            return (fiat!='BETC'?(fiat=='USDT'?val*tasa:val*tasa*this.user.user_data.data.tasa):val).toFixed(2);
        },
        chanpass(){
            document.getElementById('chanpass_btn').click();
        },
        login(){
            document.getElementById('login_btn').click();
        },
        logout(){
            this.$store.dispatch('logOut');
            this.$root.$emit('forceRender');
        },
        register(){
            document.getElementById('register_btn').click();
        },
        change_view(view){
            this.$store.dispatch('setView',view);
        },
        translate(text) {
            if(text){
                if (this.$props.translation[text]) {
                    return this.$props.translation[text][this.$props.language];
                }
                else{
                    var new_text_serched = text.replace(/ /g, "_");
                if (this.$props.translation[new_text_serched]) {
                    return this.$props.translation[new_text_serched][this.$props.language];
                }
                }
                return text;
            }
        },
        changeBalanceView(){
            let balance = 0;
            let disponible = 0
            if(this.config.SkinConfig.main.newbonus ? this.config.SkinConfig.main.newbonus : false){
                balance = this.user.user_data.data.available_balance + this.user.user_data.data.bonus + this.user.user_data.data.frozen;
                disponible = this.user.user_data.data.available_balance;
            }
            else{
                balance = this.user.user_data.data.balance;
                disponible = this.user.user_data.data.bonus_balance;
            }
            let tasa = 1;
            if(this.$props.skin.conversion ? (this.$props.skin.conversion.enabled ? true : false) : false){
                tasa = this.currency.rate;
            }
            if(this.balance.switch == 0){
                this.balance.saldo = balance;
                this.balance.conversion = (this.currency.fiat!='BETC'?(this.currency.fiat=='USDT'?balance*tasa:balance*tasa*this.user.user_data.data.tasa):balance).toFixed(2);
                this.balance.view = "SALDO";
                this.balance.switch = 1;
            }
            else{
                this.balance.saldo = disponible;
                this.balance.conversion = (this.currency.fiat!='BETC'?(this.currency.fiat=='USDT'?balance*tasa:balance*tasa*this.user.user_data.data.tasa):balance).toFixed(2);
                this.balance.view = "DISPONIBLE";
                this.balance.switch = 0;
            }
        }
    },
    mounted(){
        this.$store.dispatch("setHeaderHeight",document.getElementById("mainHeader").offsetHeight);
     }
}
</script>

<style>
</style>