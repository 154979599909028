<template>
  <div style="font-size:small" class="mb-1" :style="Skin.qbet.bet_text">
    <!-- Cashout Modal -->
    <!-- Button trigger modal -->
    <button :id="'cashoutModalBtn'+ticket_data.id+source" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="'#cashoutModal'+ticket_data.id+source" style="display:none;">
    </button>
    <button :id="'paymentModalBtn'+ticket_data.id+source" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="'#paymentModal'+ticket_data.id+source" style="display:none;">
    </button>
    <div class="modal fade" :id="'paymentModal'+ticket_data.id+source" tabindex="-1" :aria-labelledby="'paymentModalLabel'+ticket_data.id" aria-hidden="true" data-bs-backdrop="false" style="background-color:rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-dialog-centered" style="max-width:350px;">
        <div class="modal-content" style="z-index:9999">
          <div class="modal-header" :style="Skin.qbet.header">
            <h5 class="modal-title" :id="'paymentModalLabel'+ticket_data.id+source">{{translate("pay_prize")}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center" :style="Skin.qbet.fondo+Skin.qbet.text || ''">
            <div v-if="!cashout_inProgress">
                <span v-if="payment_response.status!='Ok'" style="font-size:medium;">{{translate("enter_bet_code")}}</span><br><br>
                <div class="p-2">
                    <input v-if="payment_response.status!='Ok'" v-model="PaymentCode" type="text" class="form-control">
                </div>
                <div v-if="payment_response.message!=''" class="alert" :class="payment_response.status=='Ok'?' alert-success':' alert-danger'" role="alert">
                    {{ payment_response.message }}
                </div>
                <button v-if="payment_response.status!='Ok'" @click="confirm_payment(ticket_data.id)" :style="Skin.qbet.confirm_btn" class="p-2 text-center mb-2" style="width:100%;color:white">{{ translate('Confirmar') }}</button>
            </div>
            <div v-else style="width:100%" class="text-center p-2" :style="Skin.qbet.fondo3">
                <div class="spinner-border" :style="Skin.loader" role="status">
                    <span class="visually-hidden">{{translate("loading")}}</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="'cashoutModal'+ticket_data.id+source" tabindex="-1" :aria-labelledby="'cashoutModalLabel'+ticket_data.id+source" aria-hidden="true" data-bs-backdrop="false" style="background-color:rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-dialog-centered" style="max-width:350px;">
        <div class="modal-content" style="z-index:9999">
          <div class="modal-header" :style="Skin.qbet.header">
            <h5 class="modal-title" :id="'cashoutModalLabel'+ticket_data.id+source">Cash-Out</h5>
            <button v-if="cashout_response.message==''" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center" :style="Skin.qbet.fondo+Skin.qbet.text || ''">
            <div v-if="!cashout_inProgress">
                <div v-if="cashout_response.message==''">
                    <div v-if="user.user_data.data.rol=='RESELLER'">
                        <span v-if="cashout_response.status!='Ok'" style="font-size:medium;">{{translate("enter_bet_code")}}</span><br><br>
                        <div class="p-2">
                            <input v-if="cashout_response.status!='Ok'" v-model="cashoutCode" type="text" class="form-control">
                        </div>
                    </div>
                    <div v-else>
                        <span>{{translate("confirm_cashout")}}</span><br>
                        <span style="font-size:large">{{ cashout_sanitized(cashout_value,user.user_data.data.currency) }}</span>
                    </div>
                    <button v-if="cashout_response.status!='Ok'" @click="confirm_cashout" :style="Skin.qbet.confirm_btn" class="p-2 text-center mb-2" style="width:100%;color:white">{{ translate('Confirmar') }}</button>
                </div>
                <div v-else class="text-center p-2">
                    <div class="alert" :class="cashout_response.status=='Ok'?' alert-success':' alert-danger'" role="alert">
                        {{ cashout_response.message }}
                    </div>
                    <button @click="refreshRecords()" :style="Skin.qbet.confirm_btn" class="p-2 text-center mb-2" style="width:100%;color:white">{{ translate('OK') }}</button>
                </div>
            </div>
            <div v-else style="width:100%" class="text-center p-2" :style="Skin.qbet.fondo3">
                <div class="spinner-border" :style="Skin.loader" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion" :id="'tracking-'+ticket_data.id">
        <div class="accordion-item" :style="Skin.qbet.fondo">
            <h2 class="accordion-header" :id="'heading-'+ticket_data.id">
                <div style="cursor:pointer;" :style="Skin.bet_records.ticket_header" class="accordion-button p-1 d-flex" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+ticket_data.id" :aria-expanded="index==0?true:false" :aria-controls="'collapse-'+ticket_data.id">
                    <div style="width: 45%;">
                        <span>{{ 'ID: '+ ticket_data.id }} - {{ status_formated(ticket_data.status).status }}</span>&nbsp;&nbsp;&nbsp;
                        <span v-if="canCopy(ticket_data.events)" @click="copyTicket(ticket_data.id)" style="font-size:large;" title="Copiar cupón"><i class="fas fa-copy"></i></span>
                    </div>
                    <div style="width: 55%;" class="pe-2">
                        <span class="float-end">{{translate("overall_gain")}} {{ user.user_data.data.currency!='BETC'?
                        ticket_data.possible_win.toFixed(Skin.qBet_config.decimalsQty) +' '+ user.user_data.data.currency:
                        (ticket_data.possible_win_fiat?ticket_data.possible_win_fiat+' '+(ticket_data.currency_fiat??''):
                        ticket_data.possible_win+' BETC') }}</span>
                    </div>
                </div>
            </h2>
            <div :class="index==0?'show':''" :id="'collapse-'+ticket_data.id" class="accordion-collapse collapse" :aria-labelledby="'heading-'+ticket_data.id" :data-bs-parent="'#tracking-'+ticket_data.id">
                <div class="accordion-body p-0" :style="Skin.qbet.bet_body">
                    <div v-for="odd in ticket_data.events" :key="odd.id" @click="select_event(odd)" style="cursor:pointer;">
                        <div :style="Skin.bet_records.bet_header" class="p-1">
                            <span>{{ odd.game_name }}</span>
                        </div>
                        <div class="p-1" :style="Skin.bet_records.bet_body">
                            <span>{{ odd.event_name }} {{ odd.is_live?'('+translate('live')+')':'' }}</span><br>
                            <span :style="Skin.qbet.bet_text" style="font-size:16px">{{ translate('rate') }} </span>
                            <span class="float-end" :style="Skin.qbet.bet_text2">{{ rate_conversion(odd.coeficient) }}</span><br>
                            <span>{{ odd.game_start_date }}</span>
                            <span class="badge float-end" :class="status_formated(odd.status).badge">{{ status_formated(odd.status).status }}</span>
                        </div>
                    </div>
                    <div v-if="Skin.qBet_config.risk_win" class="p-1" :style="Skin.bet_records.ticket_resume">
                        <span>{{translate("risk")}}</span>
                        <span class="float-end">{{ ticket_data.amount +' '+ user.user_data.data.currency }}</span><br>
                        <span>{{translate("gain")}}</span>
                        <span class="float-end">{{ (ticket_data.possible_win-ticket_data.amount).toFixed(Skin.qBet_config.decimalsQty) +' '+ user.user_data.data.currency }}</span><br>
                    </div>
                    <div v-else class="p-1" :style="Skin.bet_records.ticket_resume">
                        <div v-if="ticket_data.vat ? (ticket_data.vat > 0 ? true : false) : false">
                            <span>{{translate("bet_value")}}</span>
                            <span class="float-end">{{ ticket_data.amount +' '+ user.user_data.data.currency }}</span><br>
                            <span>{{translate("A ganar:")}}</span>
                            <span class="float-end">{{ ticket_data.possible_win.toFixed(Skin.qBet_config.decimalsQty) +' '+ user.user_data.data.currency }}</span><br>
                            <span>{{translate("Impuesto SRI ("+ (ticket_data.vat * 100) +"%):")}}</span>
                            <span class="float-end">{{ ticket_data.vat_amount.toFixed(Skin.qBet_config.decimalsQty) +' '+ user.user_data.data.currency }}</span><br>
                            <span>{{translate("overall_gain")}}</span>
                            <span class="float-end">{{ ticket_data.possible_win_vat.toFixed(Skin.qBet_config.decimalsQty) +' '+ user.user_data.data.currency }}</span><br>
                        </div>
                        <div v-else>
                            <span>{{translate("bet_value")}}</span>
                            <span class="float-end">{{ user.user_data.data.currency!='BETC'?ticket_data.amount +' '+ user.user_data.data.currency:(ticket_data.amount_fiat?ticket_data.amount_fiat+' '+(ticket_data.currency_fiat??''):ticket_data.amount+' BETC') }}</span><br>
                            <span>{{translate("overall_gain")}}</span>
                            <span class="float-end">{{ user.user_data.data.currency!='BETC'?ticket_data.possible_win.toFixed(Skin.qBet_config.decimalsQty) +' '+ user.user_data.data.currency:(ticket_data.possible_win_fiat?ticket_data.possible_win_fiat+' '+(ticket_data.currency_fiat??''):ticket_data.possible_win+' BETC') }}</span><br>
                        </div>
                        
                    </div>
                   <div class="my-2 p-1">
                        <button v-if="(user.user_data.data.rol=='RESELLER'&&Skin.qBet_config.print_ticket.reseller)||(user.user_data.data.rol!='RESELLER'&&Skin.qBet_config.print_ticket.web_user)" @click="printTicket(ticket_data)" :style="Skin.qbet.booking_btn" class="p-2 text-center mb-2" style="width:100%;color:white">{{translate("print_coupon")}}</button>
                        <button @click="open_cashout()" v-if="ticket_data.cashoutEnabled && cashout_enabled && Skin.bet_records.cashout && cashout_value!=0" :style="Skin.qbet.confirm_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="!cashout_loaded">
                            <span>{{ translate('close_bet_by') }}</span>&nbsp;&nbsp;
                            <span v-if="!cashout_loaded" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-else>{{ cashout_sanitized(cashout_value,user.user_data.data.currency) }}</span>
                        </button>
                        <button @click="payment()" v-if="(ticket_data.status==2 || ticket_data.status==3) && user.user_data.data.rol=='RESELLER' && payment_enabled && Skin.bet_records.cashout" :style="Skin.qbet.confirm_btn" class="p-2 text-center mb-2" style="width:100%;color:white" :disabled="!cashout_loaded">
                            <span>{{ translate('Pay_prize') }}</span>
                        </button>
                   </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../../../router/index';
export default {
  name: 'tracking',
  props: ['ticket_data','Skin','language','translation','index','currentOdds','source'],
  data(){
    return {
      cashout_value:0,
      cashout_loaded:true,
      cashout_enabled:true,
      cashoutCode:'',
      PaymentCode:'',
      cashout_inProgress:false,
      payment_inProgress:false,
      cashout_response:{message:'',status:''},
      payment_response:{message:'',status:''},
      payment_enabled: true
    }
  }, 
  methods:{
    cashout_sanitized(val,currency){
        if(this.$props.Skin.conversion ? (this.$props.Skin.conversion.enabled ? true : false) : false){
            let tasa_betc = this.user.user_data.data.tasa;
            let tasa_fiat = this.user.user_data.data.tasa_fiat;
            if(currency=='BETC' && this.$props.ticket_data.currency?(this.$props.ticket_data.currency=='USDT'?true:false):false){
                    return (val*tasa_betc).toFixed(this.$props.Skin.qBet_config.decimalsQty) + ' USDT';
            }
            if(currency=='BETC' && this.$props.ticket_data.currency?(this.$props.ticket_data.currency=='COP'?true:false):false){
                return (val*tasa_betc*tasa_fiat).toFixed(this.$props.Skin.qBet_config.decimalsQty) + ' COP';
            }
            return val.toFixed(this.$props.Skin.qBet_config.decimalsQty)+(currency!=''?' '+currency:'');
        }
        return val.toFixed(this.$props.Skin.qBet_config.decimalsQty)+(currency!=''?' '+currency:'');
    },
    translate(text){
      if (this.$props.translation[text]) {
        return this.$props.translation[text][this.$props.language];
      }
      return text;
    },
    rate_conversion(rate){
      if(this.rate_type == 'americano'){
        let value = parseFloat(rate);
        if(value >= 2){
            let result = (value-1)*100;
            return '+'+(Math.round(result));
        }
        else{
            let result = (-100)/(value-1);
            return Math.round(result);
        }
      }
      return parseFloat(rate).toFixed(2)
    },
    status_formated(status){
        if(status==1){
            return {status:this.translate('pendings'),badge:'bg-secondary'};
        }
        else if(status==2||status==3){
            return {status:this.translate('won2'),badge:'bg-success'};
        }
        else if(status==4){
            return {status:this.translate('losses'),badge:'bg-danger'};
        }
        else if(status==5){
            return {status:this.translate('paid'),badge:'bg-primary'};
        }
        else if(status==6){
            return {status:this.translate('expired'),badge:'bg-primary'};
        }
        else if(status==7){
            return {status:this.translate('cash-out'),badge:'bg-primary'};
        }
        else if(status==0){
            return {status:this.translate('cancelled'),badge:'bg-primary'};
        }
    },
    async select_event(game){
        if(game.status==1){
            let event_data = null;
            let isLive = false;
            let sport_id = 0;
            Object.keys(this.$props.currentOdds.data.sport).forEach(sport=>{
                if(game.game_id in this.$props.currentOdds.data.sport[sport].game){
                    sport_id = sport;
                }
            })
            if(sport_id!=0){
                let event_data_prematch = await this.$store.dispatch('prematch_2/getPrematchEvent',{game_id:parseInt(game.game_id),sport_id:sport_id});
                if(event_data_prematch){
                    event_data_prematch.sport = sport_id;
                    event_data_prematch.liga = game.competition_name;
                    event_data_prematch.sport_alias = game.sport_name;
                    event_data=event_data_prematch;
                }
                else{
                let event_data_live = await this.$store.dispatch('live/getLiveEvent',{game_id:parseInt(game.game_id),sport_id:sport_id}); 
                if(event_data_live){event_data=event_data_live;isLive=true;}
                }
                if(event_data){
                    if(!isLive){
                        if(router.history.current.name === 'Live_ecuabets' || router.history.current.name === 'LiveMainEcuabets'){
                            router.push({ name: 'Main_ecuabets', query: { token: this.user.token+this.user.site_id} })
                            let self  = this;
                            setTimeout(function(){
                                event_data.sport = game.sport_id;
                                self.$store.dispatch('setLobbyView','prematch2_evento');
                                self.$root.$emit('show_prematch2_event',event_data);
                                self.$store.dispatch('prematch_2/unsuscribe_all_events');
                                self.$store.dispatch('prematch_2/suscribe_event', parseInt(game.game_id));
                            },100)
                        }
                        else {
                            this.$store.dispatch('setLobbyView','prematch2_evento');
                            this.$root.$emit('show_prematch2_event',event_data);
                            this.$store.dispatch('prematch_2/unsuscribe_all_events');
                            this.$store.dispatch('prematch_2/suscribe_event', parseInt(game.game_id));
                        }
                    }
                    else{
                        if(router.history.current.name !== 'Live_ecuabets' && router.history.current.name !== 'LiveMainEcuabets'){
                            router.push({ name: 'Live_ecuabets', query: { token: this.user.token+this.user.site_id} })
                            let self  = this;
                            setTimeout(function(){
                                if(Object.keys(self.$store.getters['live/getLiveEvent']).length > 0){
                                    self.$store.dispatch('live/unsuscribe_event');
                                }
                                self.$store.dispatch('live/suscribe_event', parseInt(game.game_id));
                            },100)
                        }
                        if(this.$store.getters['live/getTargetEventId'] !=  game.game_id){
                            if(Object.keys(this.$store.getters['live/getLiveEvent']).length > 0){
                                this.$store.dispatch('live/unsuscribe_event');
                            }
                            this.$store.dispatch('live/suscribe_event', parseInt(game.game_id));
                        }
                    }
                    if(document.getElementById("myAccountCloseBtn")){
                        document.getElementById("myAccountCloseBtn").click();
                    }
                }
            }
        }
    },
    async cashout(){
        if(this.$props.ticket_data.type == 1 || this.$props.ticket_data.type == 2){
            var currentOddData =this.$props.currentOdds;
            var removeCashout = this.$store.getters['getConfig'].SkinConfig.main.removeCashout;
            var valorApostado = this.$props.ticket_data.amount;
            var logroTotal = 1;
            var valorInicialPagar = this.$props.ticket_data.possible_win*0.9;
            var valorPagarNuevo = 0;
            var numeroLogros = this.$props.ticket_data.events.length;
            var numeroLogrosPrematch = 0;

            var over15 = 0;
            var over10 = 0;
            var over5 = 0;
            var isBetCashoutable = true;
            for (const betEvent of this.$props.ticket_data.events ){
                var envivo = false;
                var ahora = Date.now();
                var start = new Date(betEvent.game_start_date);
                if(ahora>start){envivo = true;}
                var nuevoLogro = 1;
                if(betEvent.coeficient >= 15){
                    over15++;
                }
                else if(betEvent.coeficient >= 10){
                    over10++;
                }
                else if(betEvent.coeficient >= 5){
                    over5++;
                }
                if(betEvent.status == 3 || betEvent.status == 2 || betEvent.status == 1){
                    if(betEvent.status == 3 || betEvent.status == 2){
                        nuevoLogro = betEvent.coeficient;
                    }
                    else{
                        let sport_id = 0;
                        Object.keys(currentOddData.data.sport).forEach(sport=>{
                            if(betEvent.game_id in currentOddData.data.sport[sport].game){
                                sport_id = sport;
                            }
                        })
                        if(sport_id != 0){
                            let currentRate = 0;
                            Object.keys(currentOddData.data.sport[sport_id].game[betEvent.game_id].market).forEach(market=>{
                                if(currentOddData.data.sport[sport_id].game[betEvent.game_id].market[market].event[Object.keys(currentOddData.data.sport[sport_id].game[betEvent.game_id].market[market].event)[0]].id == betEvent.id){
                                    currentRate = currentOddData.data.sport[sport_id].game[betEvent.game_id].market[market].event[Object.keys(currentOddData.data.sport[sport_id].game[betEvent.game_id].market[market].event)[0]].price;
                                }
                            })
                            if (currentRate && currentRate > 1) {
                                nuevoLogro = betEvent.coeficient/currentRate;
                                if(nuevoLogro <= 0.4){ nuevoLogro = 0;}
                                if(!envivo){numeroLogrosPrematch++;}
                                else{
                                    let event_data_live = await this.$store.dispatch('live/getLiveEvent',{game_id:parseInt(betEvent.game_id),sport_id:sport_id}); 
                                    if(event_data_live?.info?(event_data_live.info.current_game_time?true:false):false){
                                        if(parseInt(event_data_live.info.current_game_time)>this.Skin.live_all_odds.blocked_time){
                                            isBetCashoutable = false;
                                            logroTotal = 0;
                                        }
                                    }
                                }
                            }
                            if (parseInt(sport_id) === 4){ // Bloquear cashout para tennis
                                isBetCashoutable = false;
                            }
                        }
                        else{
                            isBetCashoutable = false;
                            logroTotal = 0;
                        }
                    }
                }
                logroTotal *= nuevoLogro;
                if(!betEvent.type){
                    isBetCashoutable = false;
                    logroTotal = 0;
                }
                else{
                    if(removeCashout && removeCashout.length){
                        if(removeCashout.indexOf(betEvent.type) === -1){
                            isBetCashoutable = false;
                            logroTotal = 0;
                        }
                    }
                    else{
                        isBetCashoutable = false;
                        logroTotal = 0;
                    }
                }
            }
            if(over15){
                if(over15 > 1){
                    isBetCashoutable = false;
                    logroTotal = 0;
                }
                else{
                    logroTotal *= 0.08;
                }
            }
            else if(over10){
                if(over10 > 1){
                    logroTotal *= 0.2;
                }else{
                    logroTotal *= 0.3;
                }
            }
            else if(over5){
                if(over5 > 1){
                    logroTotal *= 0.3;
                }else{
                    logroTotal *= 0.5;
                }
            }
            else{
                logroTotal *= 0.9;
            }
            valorPagarNuevo = valorApostado * logroTotal;
            this.cashout_loaded=true;
            if(isBetCashoutable){
                var sanitizedValue = parseInt((valorPagarNuevo*100).toFixed(0))/100;
                if(numeroLogros > 2){
                    var maximoAcotado = valorInicialPagar*1.5;
                    if(sanitizedValue > maximoAcotado){sanitizedValue = maximoAcotado;}
                }else{
                    if(sanitizedValue > valorInicialPagar){sanitizedValue = valorInicialPagar;}
                }
                
                if(numeroLogrosPrematch >= numeroLogros){
                    if(sanitizedValue > (valorApostado*0.9)){
                        sanitizedValue = valorApostado*0.9;
                    }
                }
                var fecha_apuesta = new Date(this.$props.ticket_data.date_time).getTime();
                var minutos = (Date.now() - fecha_apuesta)/60000;
                if(minutos < 1){
                    this.cashout_enabled=false;
                }
                if(sanitizedValue==0){
                    this.cashout_enabled=false;
                }
                this.cashout_value = sanitizedValue;
                this.cashout_enabled=true;
            }
            else{
                this.cashout_enabled=false;
            }
        }
        else{
            this.cashout_enabled=false;
        }
    },
    open_cashout(){
        let modal = document.getElementById('cashoutModalBtn'+this.$props.ticket_data.id+this.$props.source);
        modal.click(); 
    },
    payment(){
        let modal = document.getElementById('paymentModalBtn'+this.$props.ticket_data.id+this.$props.source);
        modal.click(); 
    },
    async confirm_cashout(){
        this.cashout_inProgress = true;
        let cashoutResponse = await this.$store.dispatch('doCashOut',{bet_id:this.$props.ticket_data.id,price:this.cashout_value,code:this.cashoutCode,vat:this.vat});
        this.cashout_response.message=cashoutResponse.result_text;
        this.cashout_response.status=cashoutResponse.result;
        this.cashout_inProgress = false;
    },
    refreshRecords(){
        this.$parent.get_tracking("from_scratch");
    },
    async confirm_payment(bet_id){
        this.payment_inProgress = true;
        let data = {"command":"payout_bet","params":{"bet_id":bet_id,"codigo":this.PaymentCode}}
        const paymentResponse = await axios.post(this.user.domain+'/'+this.user.prefix+'/scommand?access_token='+this.user.token+'&site='+this.user.site,data);
        this.payment_response.message=paymentResponse.data.data.msg;
        this.payment_response.status=paymentResponse.data.data.result;
        this.payment_inProgress = false;
        if(this.payment_response.status=="Ok"){
            this.payment_enabled = false
            this.$parent.get_tracking("from_scratch");
        }
    },
    printTicket(ticket){
        const printConfig = this.$store.getters['getConfig'].SkinConfig.main.print_ticket_mode;
        if (printConfig === 'external'){
            const ticketData = {
                "action":"printTicketBetsw3",
                "data":{
                    "ticketId": ticket.id,
                    "status": ticket.status,
                    "username": this.user.user_data.data.name,
                    "platform": this.$store.getters['getConfig'].main.site_name,
                    "ticketDate": ticket.date_time,
                    "total": ticket.amount,
                    "currency": ticket.currency,
                    "bets": ticket.events.map(bet => {
                        return {
                            "game": bet.game_name,
                            "league": bet.competition_name,
                            "event": bet.event_name,
                            "rate": bet.coeficient,
                            "status": bet.status,
                            "gameStart": Math.floor(new Date(bet.game_start_date).getTime() / 1000) 
                        }
                    })
                }
            }
            window.parent.postMessage(ticketData, '*');
        } else {
            let url = this.user.domain+'/'+this.user.prefix+'/print.html?access_token='+this.user.token+'&id='+ticket.id+'&copia=1'+'&site='+this.user.site;
            window.open(url,"mywindow","menubar=1,resizable=1,width=800,height=800");
        }
    },
    canCopy(ticket){
        let canCopy = true;
        ticket.forEach(odd=>{
            if(odd.status!=1){
                canCopy = false;
            }
        });
        return canCopy;
    },
    async copyTicket(ticket_id){
        const response = await this.$store.dispatch("copyTicket",ticket_id);
        if(response == "ok"){
            let modal = document.getElementById('bookingModalBtn');
            modal.click();
            this.$root.$emit('newAlert',{
              label: '',
              message: 'Se ha copiado el cupón correctamente!',
              barCode: ''
            });
        }
        else{
            let modal = document.getElementById('bookingModalBtn');
            modal.click();
            this.$root.$emit('newAlert',{
              label: '',
              message: 'Ha ocurrido un error al copiar el cupón!',
              barCode: ''
            });
        }
        if(document.getElementById("myAccountCloseBtn")){
            document.getElementById("myAccountCloseBtn").click();
        }
    }
  },
  computed:{
    vat(){
      return this.$store.getters['getConfig'].SkinConfig.main.vat ?? 0
    },
    user(){
        return this.$store.getters['getUserData'];
    },
    rate_type(){
        return this.$store.getters['getRateType'];
    }, 
    jbets_modified_odds(){
        return this.$store.getters['getJbetsModifieOdds'];
    }
  },
  mounted(){
    this.cashout();
  },
  watch: { 
    currentOdds: function(newVal, oldVal) { // watch it
        this.cashout();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>
